document.addEventListener("DOMContentLoaded", () => {
  // Carousel
  const carousel = document.getElementById("carousel-style-1");
  if (carousel) {
    const dir = () => {
      if (document.dir == "rtl") {
        return "rtl";
      } else {
        return "ltr";
      }
    };

    new Glide(carousel, {
      direction: dir(),
      type: "carousel",
      perView: 4,
      gap: 20,
      breakpoints: {
        640: {
          perView: 1,
        },
        768: {
          perView: 2,
        },
      },
    }).mount();
  }

  // Editors
  // CKEditor
  const editor = document.getElementById("ckeditor");
  if (editor) {
    ClassicEditor.create(editor);
  }

  // Sortable
  let element = null;

  element = document.getElementById("sortable-style-1");
  if (element) {
    const sortable = Sortable.create(element, {
      animation: 150,
    });
  }

  element = document.getElementById("sortable-style-2");
  if (element) {
    const sortable = Sortable.create(element, {
      handle: ".handle",
      animation: 150,
    });
  }

  element = document.getElementById("sortable-style-3");
  if (element) {
    const sortable = Sortable.create(element, {
      animation: 150,
    });
  }

  // FullCalendar
  const fullCalendar = document.getElementById("calendar");
  if (fullCalendar) {
    let calendar = new FullCalendar.Calendar(fullCalendar, {
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      initialDate: "2023-01-12",
      navLinks: true, // can click day/week names to navigate views
      editable: true,
      dayMaxEvents: true, // allow "more" link when too many events
      events: [
        {
          title: "All Day Event",
          start: "2023-01-01",
        },
        {
          title: "Long Event",
          start: "2023-01-07",
          end: "2023-01-10",
        },
        {
          groupId: 999,
          title: "Repeating Event",
          start: "2023-01-09T16:00:00",
        },
        {
          groupId: 999,
          title: "Repeating Event",
          start: "2023-01-16T16:00:00",
        },
        {
          title: "Conference",
          start: "2023-01-11",
          end: "2023-01-13",
        },
        {
          title: "Meeting",
          start: "2023-01-12T10:30:00",
          end: "2023-01-12T12:30:00",
        },
        {
          title: "Lunch",
          start: "2023-01-12T12:00:00",
        },
        {
          title: "Meeting",
          start: "2023-01-12T14:30:00",
        },
        {
          title: "Happy Hour",
          start: "2023-01-12T17:30:00",
        },
        {
          title: "Dinner",
          start: "2023-01-12T20:00:00",
        },
        {
          title: "Birthday Party",
          start: "2023-01-13T07:00:00",
        },
        {
          title: "Click for Google",
          url: "http://google.com/",
          start: "2023-01-28",
        },
      ],
    });

    calendar.render();
  }
});
